<script setup>
import { ref, onMounted } from 'vue';
import { query, collection, getDocs } from 'firebase/firestore';
import HauryMap from '../components/HauryMap.vue';
import HauryProjectModal from '../components/HauryProjectModal.vue';
import HauryProjectList from '../components/HauryProjectList.vue';
import { db } from '../firebaseConfig'; 

const shownProjects = ref(null); // projects shown in modal held here
const modalTitle = ref(null);

const data = ref([]); // data from firestore held here
const mapData = ref(null); //map entries shown here
const openModal = ref(false);
const centerId = ref(null);

const shapeTitles = {
  'tohonooodham.json': 'Tohono O\'odham Nation',
  'choctaw.json': 'Choctaw Nation',
  'fortpeck.json': 'Fort Peck Tribes',
  'fortyuma.json': 'Fort Yuma',
  'navajo.json': 'Navajo Nation',
}

function showModal(){
    openModal.value = true;
}
function closeModal(){
    openModal.value = false;
}

async function pullData(dataRef){
    try {
      const q = query(collection(db, 'haury'));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        dataRef.value.push({ id: doc.id, ...doc.data() });
      });

    } catch (error) {
      console.error('Error fetching project data:', error);
    }
}

function sendData(data){
    if(data.length == 1){
      modalTitle.value = 'Project Information';
    } else {
      if(data[0].Shape){
        modalTitle.value = shapeTitles[data[0].Shape] + ' Projects';
      } else {
        modalTitle.value = data[0].Location + ' Projects';
      }
      
    }
    data.sort((a, b) => {
      if(b['Year'] - a['Year'] == 0){
        return a['Title'].localeCompare(b['Title']);
      } else {
        return b['Year'] - a['Year'];
      }
      
    })
    shownProjects.value = data;
    showModal();
}

function sendMapData(data){
  mapData.value = data;
}

function extractMapItems(data){
  var shapes = {}
  var points = {}
  for(let item of data){
    if(item.Shape){
      if(!shapes[item.Shape]){
        shapes[item.Shape] = {
          file: item.Shape,
          count: 0,
          projects: []
        }
      }
      shapes[item.Shape].count++;
      shapes[item.Shape].projects.push(item.id)
    }
    if(item.Point){
      let lat = item.Point.latitude;
      let lon = item.Point.longitude;

      if(!points[item.Location]){
        points[item.Location] = {
          latlon: [lat, lon],
          projects: []
        }
      }
      points[item.Location].projects.push(item.id)
    }
  }

  var shapeList = [];
  var pointList = [];
  for(let i = 0; i < Object.keys(shapes).length; i++){
    shapeList.push(shapes[Object.keys(shapes)[i]])
  }
  for(let i = 0; i < Object.keys(points).length; i++){
    pointList.push(points[Object.keys(points)[i]])
  }
  
  sendMapData({ points: pointList, shapes: shapeList });
}

function prepModal(id){
  var projects = getProjectsById(id, data.value)
  sendData(projects);
}

function centerMap(id){
  centerId.value = id;
}

function getProjectsById(id, data){
  if(Array.isArray(id)){
    // multiple ids, let's build a list and return
    let projectList = []
    for(let item of data){
      if(id.includes(item.id)){
        projectList.push(item);
      }
    }
    if(projectList.length > 0){
      return projectList;
    }
  } else {
    // just a singular id
    for(let item of data){
      if(item.id == id){
        return [item];
      }
    }
  }
  // if no match found
  return null;
}

onMounted(() => {
    pullData(data).then(() => {
      extractMapItems(data.value);
    });
});

</script>

<template>
<HauryMap :data="mapData" @prep-modal="(e) => prepModal(e)" :center-id="centerId" />
<HauryProjectModal :data="shownProjects" :open="openModal" :title="modalTitle" @close="closeModal" />
<HauryProjectList title="Haury Native Pathways Awards" @center-map="(e) => centerMap(e)" @show-project="(e) => prepModal(e)" :projects="data" :modal-open="openModal" />
</template>

<style scoped>


</style>