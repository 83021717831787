<script setup>
import { defineProps, ref, watch, defineEmits } from 'vue';

const props = defineProps(['title', 'description', 'projects', 'modalOpen']);
const emits = defineEmits(['showProject', 'centerMap']);
const show = ref(false);
const sortBy = ref('Year');
const fullShow = ref(true);

const projectList = ref(props.projects);
const projectsByYear = ref({})

watch(props, async (newProps) => {
    projectList.value = newProps.projects;
    projectsByYear.value = sortProjectsByYear(newProps.projects);
});

function sortProjectsByYear(projects){
    var returnObj = {}
    for(let project of projects){
        if(!returnObj[project.Year]){
            //no year item in obj yet, initialize
            returnObj[project.Year] = [];
        }
        returnObj[project.Year].push(project);
    }
    return returnObj;
}


watch(sortBy, async () => {
    changeSort();
});

function changeSort(){
    if(sortBy.value == 'Year'){
        projectsByYear.value = sortProjectsByYear(projectList.value);
    } else {
        projectList.value.sort((a, b) => {
            return a[sortBy.value].localeCompare(b[sortBy.value]);
        })
    }
}

function showHideModal(){
    if(show.value){
        show.value = false;
    } else {
        show.value = true;
    }
}

</script>

<template>
    <div class="map-info p-4 bg-none">
        <button class="btn btn-blue" @click="() => fullShow = true">About</button>
    </div>
    <div class="sidebar-container" v-if="!show && fullShow && !modalOpen">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="h3 p-0 m-0">{{ title }}</h2>
                    <button type="button" class="close" aria-label="Close" @click="() => fullShow = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col col-12">
                            <p>Native Pathways Graduate Research Awards Program is designed and administered by the Haury Program to strengthen the academic pathways for Native American and Indigenous Resilience students and scholars, with an emphasis on UArizona programs and people devoted to Native and Indigenous resilience education, research, and outreach.</p>
                            <p>These awards support the research of graduate students who focus on water, energy and food access and challenges, and/or climate change challenges faced by Native American and Indigenous communities, especially those within Arizona; and/or who have experience or are interested in strengthening their skills related to specific tribal or Native Nation’s Traditional Environmental Knowledge, values, data sovereignty, or other ways of approaching resilience challenges of Native American and Indigenous communities.</p>
                            <p>These awards are a part of a larger commitment to Indigenous Resilience by the Haury Program started by the Haury Donor-Advised Fund Board in 2020.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-blue" @click="showHideModal">View All Awards</button>
                    <button class="btn btn-blue" @click="() => fullShow = false">Explore Map</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-container" v-if="show && !modalOpen">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="h3 p-0 m-0">List of Awards</h2>
                    <button type="button" class="close" aria-label="Close" @click="showHideModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header">
                    <div class="form-inline">
                        <label class="my-1 mr-2" for="sortBy">Sort By</label>
                        <select @change="(e) => sortBy = e.target.value" class="custom-select" id="sortBy" :value="sortBy">
                            <option value="Year">Year</option>
                            <option value="Title">Title</option>
                        </select>
                    </div>

                </div>
                <div class="modal-body">
                    <div class="row mb-2" v-for="project in projectList" v-bind:key="project.id" v-show="sortBy != 'Year'">
                        <div class="col col-12 col-lg-9"><p>{{ project.Title }} ({{ project.Year }})</p></div>
                        <div class="col col-12 col-lg-3"><p><button class="btn btn-outline-red" @click="emits('showProject', [project.id]); emits('centerMap', project.id)">View Project</button></p></div>
                    </div>
                    <div v-for="year in Object.keys(projectsByYear).sort().reverse()" v-bind:key="year" v-show="sortBy == 'Year'">
                        <div class="row mb-2">
                            <div class="col col-12"><h3 class="h4 mt-0">{{ year }}</h3></div>
                        </div>
                        <div class="row mb-2" v-for="project in projectsByYear[year]" v-bind:key="project.id">
                            <div class="col col-12 col-lg-9"><p>{{ project.Title }}</p></div>
                            <div class="col col-12 col-lg-3"><p><button class="btn btn-outline-red" @click="emits('showProject', [project.id]); emits('centerMap', project.id)">View Project</button></p></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="showHideModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.sidebar-container {
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
}

.map-info {
    z-index: 800;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 600px;
}
</style>
