import { createRouter, createWebHistory } from 'vue-router';
//import CountryPage from './pages/CountryPage.vue';
import HauryPage from './pages/HauryPage.vue';

const routes = [
  /**{
    path: '/:country',
    name: 'CountryPage',
    component: CountryPage,
    props: true
  },*/
  {
    path: '/haury/native-pathways',
    name: 'HauryPage',
    component: HauryPage,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
